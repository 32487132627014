<template>
  <div class="w-full sm:w-4/5 md:3/4 2xl:w-1/2 pb-8">
    <div class="hidden md:block text-center my-10">
      <h1>{{ $t('page.login.sentence.welcome') }}</h1>
    </div>

    <div v-if="isUsingCustomToken" class="text-center mt-60">
      <div v-if="isCustomTokenFailed">
        {{ $t('sentence.default_error') }}
      </div>
      <div v-else><i class="fas fa-spin fa-spinner fa-2x"></i></div>
    </div>
    <div v-else>
      <form @submit.prevent="loginWithPassword" class="my-12">
        <div class="space-y-8">
          <base-input
            :label="$t('form.label.email')"
            name="email"
            type="email"
            v-model="v$.email.$model"
            autocomplete="username"
            left-icon="far fa-envelope"
            :error-message="emailErrorMessage"
          />

          <base-input
            :label="$t('form.label.password')"
            name="password"
            type="password"
            v-model="v$.password.$model"
            autocomplete="current-password"
            left-icon="fas fa-lock"
            :error-message="passwordErrorMessage"
          />
        </div>

        <div class="w-full text-right mt-6 mb-12">
          <router-link :to="{ name: 'recovery' }" class="text-blue-600">
            {{ $t('page.login.action.forgot_password') }}
          </router-link>
        </div>
        <div class="w-full my-6 md:my-12">
          <button
            :disabled="isSubmitDisabled"
            type="submit"
            class="btn btn-primary btn-lg w-full"
          >
            {{ $t('page.login.action.enter') }}
          </button>
        </div>
        <!-- <div class="text-center">
          {{ $t('page.register.sentence.or_access_with') }}
        </div>
        <div class="flex flex-row justify-center lg:justify-evenly lg:space-y-0 space-x-4 my-6 lg:my-10">
          <social-login-button name="facebook" @click="loginWithFacebook" />
          <social-login-button name="google" @click="loginWithGoogle" />
        </div> -->
      </form>
      <!-- <div class="text-center">
        {{ $t('page.login.sentence.has_no_account_yet_question') }}
        <router-link :to="{ name: 'register' }" class="text-blue-600">
          {{ $t('page.login.action.register') }}
        </router-link>
      </div> -->
    </div>
  </div>
  <div class="absolute top-0 right-0 m-3">
    <language-select clean />
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, reactive, toRaw, onBeforeMount, ref } from 'vue'
import { useRouter } from 'vue-router'
import { useVuelidate } from '@vuelidate/core'
import { email, required, helpers } from '@vuelidate/validators'
import { useAuth } from '@/composables/firebase/useAuth'
// import SocialLoginButton from '../components/auth/SocialLoginButton.vue'
import BaseInput from '@/components/base/BaseInput.vue'
import LanguageSelect from '@/components/settings/languageSelect.vue'
import { useI18n } from 'vue-i18n'
import { useAxios } from '@/composables/useAxios'

export default defineComponent({
  components: {
    // SocialLoginButton,
    BaseInput,
    LanguageSelect
  },
  setup () {
    const { t } = useI18n()

    const {
      login,
      loginWithFacebook,
      loginWithGoogle,
      loginWithCustomToken
    } = useAuth()

    const isUsingCustomToken = ref(false)
    const isCustomTokenFailed = ref(false)

    const form = reactive({
      email: '',
      password: ''
    })

    const rules = {
      email: {
        required: helpers.withMessage(t('validations.required'), required),
        email: helpers.withMessage(t('validations.email'), email),
      },
      password: {
        required: helpers.withMessage(t('validations.required'), required)
      }
    }

    const v$ = useVuelidate(rules, form)

    const isSubmitDisabled = computed(() => {
      return v$.value.$invalid
    })

    const emailErrorMessage = computed(() => v$.value.email.$errors[0]?.$message)

    const passwordErrorMessage = computed(() => v$.value.password.$errors[0]?.$message)

    const loginWithPassword = () => {
      if (!isSubmitDisabled.value) {
        login(toRaw(form))
      }
    }

    async function attemptLoginWithCustomToken (token: string, otp: string) {
      isUsingCustomToken.value = true

      const { httpFunctions } = await useAxios()

      try {
        const { data } = await httpFunctions.get('/api/auth', {
          params: { token, otp }
        })

        await loginWithCustomToken(data.token)
      } catch (error) {
        isCustomTokenFailed.value = true
        console.error(error)
      }
    }

    onBeforeMount(async () => {
      const router = useRouter()
      const { token, otp } = router.currentRoute.value.query

      if (typeof token === 'string' && typeof otp === 'string') {
        await attemptLoginWithCustomToken(token, otp)
      }
    })

    return {
      emailErrorMessage,
      form,
      loginWithFacebook,
      loginWithGoogle,
      loginWithPassword,
      passwordErrorMessage,
      isSubmitDisabled,
      isUsingCustomToken,
      isCustomTokenFailed,
      v$,
    }
  }
})
</script>
