import axios from "axios"

const serviceEndpoint = 'https://us-central1-many-payment-55e67.cloudfunctions.net/manyPayments'

interface LeadInterface {
  checkoutId: string
  document: string
  name: string
  email: string
  phone: Object
}

const fetchCheckoutInfo = async (checkoutId: string) => {
  const { data } = await axios.get(`${serviceEndpoint}/checkout/${checkoutId}`)

  return data
}

const submitCheckout = async (payload: Object) => {
  try {
    const { status, data } = await axios.post(`${serviceEndpoint}/subscribe`, payload)
    // const status = 201
    // const data = {
    //   subscriptionId: '123',
    //   paymentMethod: 'boleto',
    //   charge: {
    //     id: 'string',
    //     url: 'https://api.pagar.me/1/boletos/live_cliacqy4z3lzk01m5s1n80g2g',
    //     pdf: 'https://api.pagar.me/1/boletos/live_cliacqy4z3lzk01m5s1n80g2g?format=pdf',
    //     line: '23791.22928 60012.453795 31000.046909 2 93690000001000',
    //     barCode: 'https://api.pagar.me/core/v5/transactions/tran_DapYkPLt4uZqw6bl/barcode',
    //     qrCode: 'https://api.pagar.me/core/v5/transactions/tran_DapYkPLt4uZqw6bl/qrcode',
    //   }
    // }
    return {
      success: status === 201,
      data,
      error: null
    }
  } catch (error) {
    const defaultError = 'An unexpected error occurred'
    if (axios.isAxiosError(error)) {
      console.log(`Status code: ${error.response?.status}`)
      console.log(`Response data: ${error.response?.data}`)
      console.log(`Error message: ${error.message}`)
      return {
        success: false,
        error: error.response?.status !== 500 ? error.response?.data : defaultError
      }
    } else {
      console.log('unexpected error: ', error)
      return {
        success: false,
        data: null,
        error: defaultError
      }
    }
  }
}

const saveLead = async ({checkoutId, document, name, email, phone}: LeadInterface) => {
  await axios.post(`${serviceEndpoint}/lead`, {checkoutId, document, name, email, phone})
}

const researchCoupon = async (coupon: string) => {
  const result = await axios.get(`${serviceEndpoint}/coupon/${coupon}`)
  return result.data
}

export default {
  fetchCheckoutInfo,
  submitCheckout,
  saveLead,
  researchCoupon
}