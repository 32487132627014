import { ref } from 'vue'
import { useRouter } from 'vue-router'
import { auth, firebase } from '@/plugins/firebase'
import Swal from 'sweetalert2'
import { useI18n } from 'vue-i18n'
import localforage from 'localforage'
interface LoginError extends Error {
  code?: string
}

export const useAuth = () => {
  const router = useRouter()

  const { t } = useI18n()

  const isLoggedIn = ref(auth.currentUser !== null)

  auth.onAuthStateChanged((user) => {
    isLoggedIn.value = user !== null
  })

  const logout = async () => {
    try {
      await localforage.clear()
      window.localStorage.clear()
      await auth.signOut()
      window.location.href = '/login'
    } catch (error) {
      // nothing
    }
  }

  const redirectAfterLogin = () => {
    router.replace({ name: 'change-profile' })
  }

  const alertOnError = (error: LoginError) => {
    const text = () => {
      const errorMessages = {
        'auth/invalid-email': t('sentence.invalid_email'),
        'auth/wrong-password': t('sentence.wrong-password'),
        'auth/user-not-found': t('sentence.user-not-found'),
        'auth/too-many-requests': t('sentence.too-many-requests')
      }

      console.log(error.code)

      const defaultMessage = t('sentence.default_error')

      const code = error.code as keyof typeof errorMessages

      console.error('code', error.code)

      const { [code]: errorMessage = defaultMessage } = errorMessages

      return errorMessage
    }

    if (error.code !== 'auth/popup-closed-by-user') {
      Swal.fire({
        backdrop: true,
        title: 'Oops..',
        text: text()
      })
    }
  }

  const login = async ({ email, password }: { email: string; password: string }) => {
    try {
      await auth.signInWithEmailAndPassword(email, password)

      redirectAfterLogin()
    } catch (error) {
      // @ts-ignore
      alertOnError(error)
    }
  }

  const loginWithFacebook = async () => {
    const facebookProvider = new firebase.auth.FacebookAuthProvider()

    auth.useDeviceLanguage()

    try {
      await auth.signInWithPopup(facebookProvider)
    } catch (error) {
      // @ts-ignore
      alertOnError(error)
    }
  }

  const loginWithGoogle = async () => {
    const googleProvider = new firebase.auth.GoogleAuthProvider()

    auth.useDeviceLanguage()

    try {
      const { credential } = await auth.signInWithPopup(googleProvider)

      if (credential) {
        await auth.signInWithCredential(credential)

        redirectAfterLogin()
      }
    } catch (error) {
      // @ts-ignore
      alertOnError(error)
    }
  }

  const sendPasswordResetEmail = async (email: string) => {
    try {
      await auth.sendPasswordResetEmail(email)

      Swal.fire({
        backdrop: true,
        title: t('state.recovery_password.title'),
        text: t('state.recovery_password.message'),
        confirmButtonText: t('state.recovery_password.action_confirm')
      })

      router.push({ name: 'login' })
    } catch (error) {
      // @ts-ignore
      alertOnError(error)
    }
  }

  async function loginWithCustomToken (authToken: string) {
    try {
      await auth.signInWithCustomToken(authToken)

      redirectAfterLogin()
    } catch (error) {
      alertOnError(error)
    }
  }

  return {
    isLoggedIn,
    login,
    loginWithFacebook,
    loginWithGoogle,
    logout,
    sendPasswordResetEmail,
    loginWithCustomToken
  }
}
