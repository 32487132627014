import { App, createApp } from 'vue'
import VueApp from './App.vue'
import router from './router'
import store from './store'
import { auth } from './plugins/firebase'
import { initFacebookPixel } from './plugins/facebook/pixel'
import { sentry } from './plugins/sentry'
import { nps } from './plugins/nps'
import { createI18n } from './plugins/i18n'
import { initGTM } from './plugins/gtm'
import Maska from 'maska'
import 'windi.css'

const isDevelopment = process.env.NODE_ENV === 'development'
export const isProduction = isDevelopment === false

const gtmContainerId = process.env.VUE_APP_GTM_CONTAINER_ID || null

const mountApp = async () => {
  const app = createApp(VueApp)
  const { i18n } = await createI18n()

  app.use(store)
  app.use(router)
  app.use(i18n)
  app.use(Maska)
  
  if (gtmContainerId) {
    app.use(initGTM(process.env.VUE_APP_GTM_CONTAINER_ID, router, isProduction))
  }

  app.mount('#app')

  if (isProduction) {
    const { initSentry, errorHandler } = sentry()

    initFacebookPixel(['796704134043684', '768749882041585'])

    initSentry()
    app.config.errorHandler = errorHandler
  }

  return app
}

const initializeApp = () => {
  let app: null | App<Element> = null

  auth.onAuthStateChanged(async (user) => {
    if (app === null) {

      if (isDevelopment && user) {
        console.log('> USER', user.uid)
      }

      app = await mountApp()
    }
    
    if (user) {
      store.dispatch('user/fetch')
    }

    if (isProduction) {
      const { setUser, resetUser } = sentry()
      const { initNps } = nps()
      
      if (user) {
        setUser(user.uid)
        
        await initNps({
          id: user.uid,
          name: user.displayName,
          email: user.email,
          createdAt: user.metadata.creationTime ? new Date(user.metadata.creationTime).toISOString() : undefined
        })
      } else {
        resetUser()
      }
    }
  })
}

initializeApp()
