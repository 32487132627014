import checkoutService from "@/services/checkoutService"

const state = {
  isLoaded: false,
  installments: [],
  products: [],
  paymentMethods: [],
  paymentFlags: [
    'visa',
    'mastercard',
    'american-express',
    'discover',
    'diners-club',
    'jcb'
  ],
  checkoutFormData: {
    name: '',
    email: '',
    phone: '',
    document: '',
    zipCode: '',
    street: '',
    number: '',
    complement: '',
    neighborhood: '',
    city: '',
    state: '',
    cardNumber: '',
    cardHolderName: '',
    cardExpirationDate: '',
    cardCVV: '',
    paymentMethod: '',
    installments: 1,
    coupon: ''
  },
  submitCheckoutStatus: {},
  discount: null,
  isInternational: false
}

const getters = {}

const mutations = {
  setLoaded (state: any) {
    state.isLoaded = true
  },
  setInstallments (state: any, payload: any[]) {
    state.installments = payload
  },
  setProducts (state: any, payload: any[]) {
    state.products = payload
  },
  setPaymentMethods (state: any, payload: any[]) {
    const firstPaymentMethod = payload.includes('credit_card') ? 'credit_card' : payload[0]
    state.checkoutFormData.paymentMethod = firstPaymentMethod
    state.paymentMethods = payload
    if (payload.includes('boleto')) {
      state.paymentFlags = [...state.paymentFlags, 'boleto']
    }
  },
  updateCheckoutFormData (state: any, payload: any) {
    const checkoutFormData = {
      ...state.checkoutFormData,
      ...payload
    }

    if (checkoutFormData.paymentMethod === 'boleto') {
      checkoutFormData.installments = 1
    }

    state.checkoutFormData = checkoutFormData
  },
  updateDiscount (state: any, payload: any) {
    state.discount = payload
  },
  submitCheckoutSuccess (state: any, data: any) {
    state.submitCheckoutStatus.success = true
    state.submitCheckoutStatus.data = data
  },
  submitCheckoutFailure (state: any, error: string) {
    state.submitCheckoutStatus.success = false
    state.submitCheckoutStatus.error = error
  },
  resetSubmitCheckoutStatus (state: any) {
    state.submitCheckoutStatus = {}
  },
  setSelectedInstallment (state: any, payload: any) {
    state.checkoutFormData = {
      ...state.checkoutFormData,
      installments: payload
    }
  },
  setIsInternational (state: any, payload: boolean) {
    state.isInternational = payload
  }
}

const actions = {
  async fetchCheckoutInfo ({ commit }: any, checkoutId: string) {
    const checkoutInfo = await checkoutService.fetchCheckoutInfo(checkoutId)
    commit('setLoaded')
    commit('setProducts', checkoutInfo.products)
    commit('setPaymentMethods', checkoutInfo.paymentMethod)
    commit('setInstallments', checkoutInfo.installments)
    commit('setSelectedInstallment', checkoutInfo.installments[0].installment)
    commit('setIsInternational', checkoutInfo.international || false)
  },
  async makePurchase({ commit }: any, payload: any) {
    commit('resetSubmitCheckoutStatus')
    const {success, data, error} = await checkoutService.submitCheckout(payload)
    // await new Promise((resolve) => setTimeout(resolve,5000))

    if (success) {
      commit('submitCheckoutSuccess', data)
    } else {
      commit('submitCheckoutFailure', error)
    }
  }
}

export const checkout = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}