
import { computed, defineComponent, reactive, toRaw, onBeforeMount, ref } from 'vue'
import { useRouter } from 'vue-router'
import { useVuelidate } from '@vuelidate/core'
import { email, required, helpers } from '@vuelidate/validators'
import { useAuth } from '@/composables/firebase/useAuth'
// import SocialLoginButton from '../components/auth/SocialLoginButton.vue'
import BaseInput from '@/components/base/BaseInput.vue'
import LanguageSelect from '@/components/settings/languageSelect.vue'
import { useI18n } from 'vue-i18n'
import { useAxios } from '@/composables/useAxios'

export default defineComponent({
  components: {
    // SocialLoginButton,
    BaseInput,
    LanguageSelect
  },
  setup () {
    const { t } = useI18n()

    const {
      login,
      loginWithFacebook,
      loginWithGoogle,
      loginWithCustomToken
    } = useAuth()

    const isUsingCustomToken = ref(false)
    const isCustomTokenFailed = ref(false)

    const form = reactive({
      email: '',
      password: ''
    })

    const rules = {
      email: {
        required: helpers.withMessage(t('validations.required'), required),
        email: helpers.withMessage(t('validations.email'), email),
      },
      password: {
        required: helpers.withMessage(t('validations.required'), required)
      }
    }

    const v$ = useVuelidate(rules, form)

    const isSubmitDisabled = computed(() => {
      return v$.value.$invalid
    })

    const emailErrorMessage = computed(() => v$.value.email.$errors[0]?.$message)

    const passwordErrorMessage = computed(() => v$.value.password.$errors[0]?.$message)

    const loginWithPassword = () => {
      if (!isSubmitDisabled.value) {
        login(toRaw(form))
      }
    }

    async function attemptLoginWithCustomToken (token: string, otp: string) {
      isUsingCustomToken.value = true

      const { httpFunctions } = await useAxios()

      try {
        const { data } = await httpFunctions.get('/api/auth', {
          params: { token, otp }
        })

        await loginWithCustomToken(data.token)
      } catch (error) {
        isCustomTokenFailed.value = true
        console.error(error)
      }
    }

    onBeforeMount(async () => {
      const router = useRouter()
      const { token, otp } = router.currentRoute.value.query

      if (typeof token === 'string' && typeof otp === 'string') {
        await attemptLoginWithCustomToken(token, otp)
      }
    })

    return {
      emailErrorMessage,
      form,
      loginWithFacebook,
      loginWithGoogle,
      loginWithPassword,
      passwordErrorMessage,
      isSubmitDisabled,
      isUsingCustomToken,
      isCustomTokenFailed,
      v$,
    }
  }
})
