import { NavigationGuardNext, RouteLocationNormalized } from 'vue-router'
import { auth } from '@/plugins/firebase'

const publicRoutes = [
  'login',
  'logout',
  'register',
  'recovery',
  'thanks',
  'checkoutPage',
]

export const authGuard = (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) => {
  const isLoggedIn = auth.currentUser !== null

  const requiresAuth = !publicRoutes.includes(to.name as string)

  const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title);

  if(nearestWithTitle) {
    document.title = nearestWithTitle.meta.title as string
  }
  
  if (isLoggedIn) {
    if (!requiresAuth && to.name !== 'logout' && to.name !== 'checkoutPage') {
      return next({ name: 'change-profile' })
    }
  } else if (requiresAuth) {
    return next({ name: 'login' })
  }

  return next()
}
